<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <template #activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-action class="mr-4">
          <v-icon small>
            fal fa-file-check fa-fw
          </v-icon>
        </v-list-item-action>
        <v-list-item-content><v-list-item-title>Sample OneChoice Report</v-list-item-title></v-list-item-content>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>Download Sample OneChoice Report</v-card-title>
      <v-card-subtitle>{{ client.name }}</v-card-subtitle>
      <v-divider />
      <v-card-text>
        <v-text-field
          v-model="uuid"
          label="Report UUID"
          :rules="[
            v => v.length === 36 || 'Invalid UUID',
            v => v.match(/^[a-f0-9-]+$/i) || 'Invalid UUID',
          ]"
          :error-messages="errors"
          validate-on-blur
          autofocus
          prepend-icon="fal fa-fw fa-key"
        />
        <v-select
          v-model="language"
          label="Language"
          :items="$languages"
          prepend-icon="fal fa-fw fa-language"
        />
        <v-checkbox
          v-model="matrix"
          label="Include MedsMatrix"
          dense
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!uuid || errors.length > 0"
          :loading="loading"
          @click="download"
        >
          Download
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    client: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data () {
    return {
      uuid: null,
      language: this.client?.all_meta?.reports_language || 'en',
      matrix: false,
      dialog: false,
      errors: [],
      loading: false,
    }
  },
  watch: {
    uuid () {
      this.errors = []
    },
  },
  methods: {
    download () {
      if (this.uuid) {
        this.loading = true
        this.axios.get(`/lab_results/${this.uuid}/interpretation/pdf`, {
          params: {
            redacted: true,
            client: this.client.uuid,
            lang: this.language,
            matrix: this.matrix,
            output: 'base64',
          },
        })
          .then((response) => {
            const link = document.createElement('a')
            link.href = 'data:application/pdf;base64,' + response.data.report_content
            link.setAttribute('download', this.client.name + ' - Arkstone.pdf')
            document.body.appendChild(link)
            link.click()
            link.remove()
            this.dialog = false
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.errors.push('Lab result not found')
            } else {
              this.$toast.error(error.response.data.message)
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
  },
}
</script>
